.buttonMain {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.0016em;
    border: none;
    border-radius: 12px;
    color: white;
    padding: 10px 16px;

    &:disabled,
    button[disabled] {
        border: 1px solid gray !important;
        background-color: gray !important;
        color: white;
    }
}

.icon {
    svg {
        width: 20px;
        height: 20px;
        margin-right: 10px;
    }
}

.noBackground {
    border: 1px solid rgba(5, 121, 83, 0.05);
    color: #057953;
    svg {
        mask {
            path {
                color: white;
            }
        }
    }
}

.blackBordered {
    background: #efefef;
    color: #464a4d !important;
    border: 1px solid #464a4d !important;
}
