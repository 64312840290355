.memberList {
    border-radius: 24px;

    &Item {
        border-bottom: 1px solid #e6e6e6;
        padding: 20px 10px;
        &Inner {
            .innerButton {
                background: none;
                border: none;
            }
        }
        button {
            // border: none;
            background: none;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
        }
        &:last-child {
            border: none !important;
        }

        &Left {
            position: relative;
            img {
                width: 56px;
                height: 56px;
                object-fit: cover;
                border-radius: 50%;
            }
        }
        &Right {
            margin-left: 24px;
            h4 {
                font-weight: 500;
                font-size: 18px;
                line-height: 27px;
                color: #313538;
                margin-bottom: 8px;
            }
            p {
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
                color: #989a9c;
                margin-bottom: 0px;
            }
        }
    }
}
