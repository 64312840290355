.styledDatePicker {
    width: 100%;
    height: 56px;
    border-radius: var(--space-x1);
    border: none;
    padding: 12px 24px;
    padding-left: 40px;
    color: #495673;
    background: #f5f5f8;
}
.styledDiv {
    position: relative;
    svg {
        position: absolute;
        margin-top: -37px;
        display: block;
        width: 20px;
        height: 20px;
        margin-left: 12px;
    }
}
