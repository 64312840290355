// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

// Default Layout themes
@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
@import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";

// Header themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/base/dark.scss";

// Header Menu themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/menu/dark.scss";

// Brand themes
.brand-dark {
  @import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
}
// Light
.brand-light {
  @import "./_metronic/_assets/sass/themes/layout/brand/light.scss";
}

// Aside themes
// Dark
//@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
// Light
// @import "./_metronic/_assets/sass/themes/layout/aside/light.scss";

// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }

.example-highlight > pre {
  background: none transparent !important;
  margin: 0 !important;
  padding-top: 0.5rem;
  code {
    overflow-y: auto;
    display: block;

    span {
      background: none transparent !important;
    }
  }
}

.json > pre {
  background: none #fff !important;
}

.example-code > .json {
  background: none #fff !important;
}

.symbol.symbol-45 > svg {
  width: 100%;
  max-width: 45px;
  height: 45px;
}

.react-bootstrap-table {
  overflow-x: auto;
}

.react-bootstrap-table {
  th {
    outline: none;

    &.sortable {
      .svg-icon-sort {
        opacity: 0;
      }

      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }
  }

  .table.table-head-custom thead tr {
    .sortable-active {
      color: $primary !important;
    }
  }
}

.cursor-default {
  cursor: default !important;
}

.display-block {
  display: block;
}

.aside-menu-wrapper {
  background-color: #fdfcfd !important;
}
.aside-menu {
  background-color: #fdfcfd !important;
  .menu-link {
    background-color: #fdfcfd !important;
  }
}
.btn-primary {
  background-color: #057953 !important;
}
.makeStyles-tableWrapper-166 {
  max-height: 100% !important;
}

.react-time-picker__wrapper {
  display: flex;
}
.react-time-picker__inputGroup {
  input {
    width: 40px !important;
  }
}
.react-time-picker__clock-button {
  display: none !important;
}
.carousel-slider {
  height: 200px;
}

.selectImg {
  label {
    margin-top: 20px;
    border-radius: 8px !important;
    border: 1px solid #b3b3b3 !important;
    color: #464a4d;
  }
}
.fileSlider {
  height: 200px;
}
.otherPhotoImgSide {
  video {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}
.deleteIcon {
  position: absolute;
  top: 10px;
  left: 30px;
  i {
    cursor: pointer;
    font-size: 10px;
    color: rgb(112, 110, 110);
  }
}

.css-1odmjh2-singleValue {
  z-index: 0 !important;
}
.css-z3iohr-Control {
  min-height: 52px !important;
  height: auto !important;
}
.css-14jsuut-ValueContainer {
  height: auto !important;
}
.react-select-category-value-select-listbox {
  z-index: 9999;
}
.carousel {
  .thumbs-wrapper {
    display: none !important;
  }
}
.MuiTabPanel-root {
  padding: 0px !important;
}

.MuiAccordion-root {
  box-shadow: none !important;
  // background-color: #fdfcfd !important;
}
.aside-menu .menu-nav .menu-item > .menu-link .menu-icon {
  color: black;
}
.aside-menu .menu-nav .menu-item > .menu-link .menu-text {
  color: black;
}
.menu-nav .Mui-expanded {
  background: rgba(5, 121, 83, 0.05) !important;
}
.aside-menu {
  background-color: white !important;
}
.cardInner {
  padding: 10px;
}
.innerTitle {
  p {
    margin-bottom: 0px;
  }
}
.aside-minimize {
  .menu-body_item {
    span,
    button {
      display: none;
    }
  }
}
.aside-minimize-hover {
  .aside {
    width: 70px !important;
  }
  .brand-logo {
    display: none;
  }
}
#progressOrder {
  outline: none;
}
// #category-value-select {
//   z-index: 99 !important;
//   position: relative;
// }
#react-select-category-value-select-listbox {
  z-index: 99999 !important;
  position: relative;
}
.category-value-select__menu-portal {
  z-index: 999 !important;
  position: relative;
}
.category-value-select__menu-list {
  z-index: 99999 !important;
  position: relative;
}
.creatable-react-select__control {
  min-height: 52px !important;
  border-radius: 8px !important;
  border: 1px solid #b3b3b3 !important;
}
