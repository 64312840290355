.search-input {
    position: relative;
    height: 41px;
    width: 100%;
    background: #ffffff;

    input {
        width: 100%;
        outline: none;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0.16%;
        color: #676869;
        background: #ffffff;
        border: 1px solid #e6e6e6;
        padding: 10px 20px 10px 50px;
    }
    svg {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 20px;
    }
}
