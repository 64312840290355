.menu-child {
  display: block !important;
  transition: 0.3s ease-in-out !important;
  a {
    margin-left: 20px;
    color: #253746 !important;
    font-weight: 400;
    font-size: 1rem;
    transition: 0.3s ease-in-out;
    &:hover {
      color: #057953 !important;
    }
  }
}

.MuiPaper-elevation {
  box-shadow: none !important;
  border: none !important;
}
.MuiAccordionSummary-root {
  width: 220px;
}

.menu-body {
  padding: 0px;
  &_item {
    list-style: none;
    padding: 12px;
    a {
      text-decoration: none;
      display: flex;
      align-items: center;
    }
    span {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.0016em;
      color: #313538;
      margin-left: 10px;
    }
    .MuiButton-text {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.0016em;
      text-transform: initial;
      font-family: Poppins, Helvetica, "sans-serif" !important;
      color: #313538;
      &:hover {
        background-color: transparent !important;
      }
    }
    svg {
      width: 20px;
      height: 20px;
      color: black;
      path {
        stroke: black !important;
      }
    }
  }
}
.span-active {
  color: #057953 !important;
  font-weight: 600 !important;
}
.icon-active {
  svg {
    color: #057953 !important;
  }
  path {
    stroke: #057953 !important;
  }
}
.li-active {
  background: rgba(5, 121, 83, 0.05) !important;
}
.active-accordion {
  background: rgba(5, 121, 83, 0) !important;
}

.MuiAccordionSummary-content {
  margin: 0px !important;
}
.MuiAccordionSummary-root {
  min-height: auto !important;
}
.MuiAccordionDetails-root {
  padding: 16px !important;
}
// @media (min-width: 992px) {
//   .menu-body {
//     &_item {
//       span {
//         display: none;
//       }
//     }
//   }
// }
